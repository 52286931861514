<template>
  <RouterView />
</template>

<script>

export default {
  name: 'App',
}
</script>

<style lang="scss">
@media (max-width: 767px) {
  .header-bg {
    background: url("./assets/img/portada_web.jpg")top / cover no-repeat;
    width: 100%;
    max-width: 100%;
  }
}

@media (max-width: 991px) {
  .header-bg {
    background: url("./assets/img/portada_web.jpg")top / cover no-repeat;
    width: 100%;
    max-width: 100%;
    height: 100vh;
  }
}

.header-bg-2 {
  background: url("./assets/img/portada_web.jpg")top / cover no-repeat;
  width: 100%;
  max-width: 100%;
  height: 300px;
}

@media (min-width: 992px) {
  .header-bg {
    background: url("./assets/img/portada_web.jpg")top / cover no-repeat;
    height: 100vh;
    width: 100%;
    max-width: 100%;
  }
}

@media (max-width: 767px) {
  .bg-texto {
    width: 100vw;
    background: rgba(0,0,0,0.55);
    height: 100vh;
  }
}

@media (min-width: 768px) {
  .bg-texto {
    width: 100vw;
    height: 100vh;
    background: rgba(0,0,0,0.55);
  }
}

@media (min-width: 768px) {
  .img-logo {
    max-width: 200px;
    max-height: 200px;
    margin-bottom: 2rem;
    margin-top: 2rem;
  }
}

@media (max-width: 767px) {
  .img-logo {
    max-width: 140px;
    max-height: 200px;
    margin-bottom: 2rem;
    margin-top: 2rem;
  }
}

.max-container {
  max-width: 500px;
}

.small-text {
  font-size: 12px;
}

body {
  font-family: montserrat;
}

.max-column {
  max-width: 600px;
}

.max-column-2 {
  max-width: 800px;
}

body {
  max-height: 100%;
}

.bs-icon {
  --bs-icon-size: .8rem;
  display: flex;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  font-size: var(--bs-icon-size);
  width: calc(var(--bs-icon-size) * 2);
  height: calc(var(--bs-icon-size) * 2);
  color: var(--bs-primary);
}

h1 {
  font-size: 4rem;
}

@media (max-width: 767px) {
  h1 {
    font-size: 2.3rem;
  }
}

a {
  color: white;
  text-decoration: none;
}

p.horario {
  margin-top: 0;
  margin-bottom: 0;
  font-weight: 600;
}

.btn-primary {
  --bs-btn-color: #fff;
  --bs-btn-bg: #d82804;
  --bs-btn-border-color: #d82804;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #921a02;
  --bs-btn-hover-border-color: #921a02;
  --bs-btn-focus-shadow-rgb: 49,132,253;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #921a02;
  --bs-btn-active-border-color: #921a02;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: var(--bs-gray);
  --bs-btn-disabled-border-color: var(--bs-gray);
}

.recuadro {
  border: 1px solid white;
  padding: 2rem;
  max-width: 600px;
}


</style>
