import { createRouter, createWebHistory } from 'vue-router'

import InicioPublico from '../components/InicioPublico.vue'


const routes = [
    {
        path: '/',
        name: 'Carnicería Débora - Hola',
        component: InicioPublico
    },
    {
        path: '/sorteo',
        name: 'Carnicería Débora - Sorteo',
        component: () => import(/** webpackChunkName: SorteoPage */ '../components/SorteoCondiciones.vue')
    },
    {
        path: '/:pathMatch(.*)*',
        redirect: '/'
    }
]

const router = createRouter({
    history: createWebHistory(),
    routes
})

router.beforeEach((to, from, next) => {
    document.title = to.name;
    next();
})

export default router